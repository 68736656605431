import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function BankId({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        strokeRound
        d="M7.57349 12.5221L8.57618 5.83941C8.18118 5.83941 7.50512 5.83941 7.50512 5.83941C7.00378 5.83941 6.3581 5.54222 6.1682 4.99603C6.10743 4.8113 5.9631 4.17676 6.79108 3.55828C7.08733 3.34142 7.27724 3.10045 7.31522 2.91571C7.3532 2.72294 7.30762 2.55427 7.17849 2.42575C6.99618 2.24101 6.63916 2.1366 6.18339 2.1366C5.41618 2.1366 4.87686 2.60246 4.82368 2.93981C4.7857 3.18881 4.96801 3.38961 5.12753 3.51812C5.60609 3.89563 5.72003 4.44182 5.42378 4.95587C5.11993 5.48599 4.45907 5.83138 3.75263 5.83941C3.75263 5.83941 3.05378 5.83941 2.65878 5.83941C2.56763 6.49001 1.07879 16.4659 0.964844 17.2531H6.87464C6.92782 16.9077 7.20128 15.0201 7.57349 12.5221Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M0.645672 19.5663H3.06884C4.10192 19.5663 4.35259 20.1205 4.27663 20.6265C4.21586 21.0362 3.94999 21.3414 3.49423 21.5422C4.07153 21.7751 4.29942 22.1366 4.21586 22.7068C4.10951 23.4217 3.52461 23.9518 2.7574 23.9518H0L0.645672 19.5663ZM2.24846 21.3815C2.71942 21.3815 2.93971 21.1165 2.98528 20.8032C3.03086 20.4659 2.88653 20.233 2.41557 20.233H1.99778L1.83067 21.3815H2.24846ZM1.99019 23.2691C2.47634 23.2691 2.7574 23.0603 2.82576 22.6346C2.87894 22.2651 2.68144 22.0482 2.21048 22.0482H1.73952L1.55721 23.2771H1.99019V23.2691Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M7.61438 23.984C6.9839 24.0322 6.68006 23.9599 6.52813 23.6707C6.1939 23.8876 5.82169 24.0001 5.42669 24.0001C4.71265 24.0001 4.46198 23.6065 4.53035 23.1727C4.56073 22.9639 4.67467 22.7631 4.85698 22.5944C5.25198 22.233 6.22429 22.1848 6.60409 21.9117C6.63448 21.6065 6.52054 21.494 6.16352 21.494C5.74573 21.494 5.39631 21.6386 4.79621 22.0723L4.94054 21.0764C5.45708 20.6828 5.95842 20.498 6.53573 20.498C7.27256 20.498 7.92582 20.8193 7.80428 21.6707L7.65996 22.6346C7.60678 22.9719 7.62198 23.0764 7.979 23.0844L7.61438 23.984ZM6.52054 22.4659C6.18631 22.6908 5.56342 22.6507 5.49506 23.1165C5.46467 23.3334 5.59381 23.494 5.7989 23.494C5.9964 23.494 6.23948 23.4057 6.43698 23.2611C6.42179 23.1808 6.42938 23.1005 6.45217 22.9478L6.52054 22.4659Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M8.78672 20.5543H10.0477L9.97931 20.996C10.3819 20.6346 10.6858 20.498 11.0808 20.498C11.7872 20.498 12.1138 20.9559 11.9999 21.7029L11.6733 23.9438H10.4123L10.6858 22.0884C10.7389 21.7511 10.6402 21.5904 10.3971 21.5904C10.1996 21.5904 10.0173 21.7029 9.84258 21.9519L9.55393 23.9358H8.29297L8.78672 20.5543Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M12.9933 19.5663H14.2543L13.9352 21.7189L15.143 20.5542H16.7002L15.1506 22L16.3964 23.9438H14.8088L13.8517 22.3695H13.8365L13.6086 23.9358H12.3477L12.9933 19.5663Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M17.4506 19.5663H18.9014L18.2634 23.9438H16.8125L17.4506 19.5663Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M19.6066 19.5663H21.6804C23.2831 19.5663 23.7465 20.7952 23.5946 21.8153C23.4503 22.8113 22.7058 23.9438 21.3005 23.9438H18.9609L19.6066 19.5663ZM20.9511 22.8996C21.6576 22.8996 22.045 22.5301 22.1589 21.751C22.2425 21.1727 22.0754 20.6024 21.293 20.6024H20.9055L20.5713 22.8996H20.9511Z"
        fill={color}
      />
      <BaseIconPathStyle
        strokeRound
        d="M15.516 0H9.47707L8.67188 5.40562H9.69735C10.2595 5.40562 10.7912 5.13253 11.0191 4.73896C11.095 4.61044 11.1254 4.49799 11.1254 4.39357C11.1254 4.16868 10.9811 4 10.8368 3.88755C10.4418 3.5743 10.3582 3.24498 10.3582 3.01205C10.3582 2.96386 10.3582 2.92369 10.3658 2.88353C10.4494 2.31325 11.1786 1.69478 12.1433 1.69478C12.7206 1.69478 13.1612 1.83936 13.4271 2.10442C13.6625 2.33735 13.7537 2.66667 13.6853 3.01205C13.6018 3.42169 13.2144 3.75904 12.9941 3.92771C12.4092 4.36145 12.4851 4.73896 12.5231 4.85141C12.6447 5.18876 13.108 5.40562 13.465 5.40562H15.0298C15.0298 5.40562 15.0298 5.40562 15.0298 5.41365C17.1568 5.42972 18.2962 6.46586 17.9392 8.8755C17.6049 11.1165 15.9794 12.0803 14.0423 12.0964L13.2751 17.2691H14.407C19.1849 17.2691 23.0894 14.0241 23.8566 8.8996C24.8061 2.53815 20.9852 0 15.516 0Z"
        fill={color}
      />
    </BaseSvgStyle>
  );
}

export default BankId;
